.report-container {
    height: 100vh; 
    width: 100%;
    padding-left: 2%;
    padding-right: 1.2vh;
}

.powerbi-container {
    height: calc(100% - 60px); // Assuming the navbar is 60px high; adjust as necessary
    width: 100%;
    
    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #D82121;
        
    }
}