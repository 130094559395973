
.commodity-screen{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0; 
    background-color: #f7f7f7;
    background-image: url('../../assets/images/background/sign_up_bg.jpg');
    background-size: cover; 
    background-repeat: no-repeat;  
    background-position: center;  
}
.commodity-selection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vh;
    height: 60vh;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid rgb(4, 4, 4);
    background-color: #fff;
    border-radius: 10px;
    overflow-y: auto;
    
    .title {
        margin: 0;
        margin-bottom: 1rem;
        justify-content: center;
        align-items: center;
    }

    .description {
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .mt-2 {
        justify-content: center;
        align-items: center;
        min-height: 22px;
        padding: 5px;
        width: 100% !important;
        margin-bottom: 2rem;
    }
    .btn {
        background-color: #d82222;
        width: 80%;
        border: none;
        margin-top: 20px;
    }
    
    .chip, .highlightOption, .multiSelectContainer li:hover {
        background-color: #d92222 !important;
    }
}
