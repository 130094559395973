$primary-color: #D82121;
$hypertext-color: #007bff;
$focus-border-color: #D82121;
$focus-box-shadow-color: #ffffff;
$background-color: #ffffff;
$text-color: #495057;
$weak-color: #F7BA28;
$strong-color: #2ECC71;
$box-border-color: #ced4da;
.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

  
  .form-container {
    text-align: center;
    width: 100%;
    max-width: 30%;
    margin: auto;
    padding: 2%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  
  .description {
    margin-bottom: 3em;
  }
  
  .forgot-password-form .input-group {
    margin-bottom: 2em;
  }
  
  .email-input {
    border-radius: 4px; 
    height: 2.5rem;
    width: 100%;
    padding: 10px;
    margin-bottom: 2em; 
    border: 1px solid $box-border-color;
    &:focus {
        color: $text-color;
        background-color: $background-color;
        border-color: $focus-border-color;
        outline: 0;
        box-shadow: 0 0 0 .2rem $focus-box-shadow-color;
      }
  }

  .otp-input {
    border-radius: 4px; 
    height: 2.5rem;
    width: 100%;
    padding: 10px;
    margin-bottom: 2em;
    border: 1px solid $box-border-color;
    &:focus {
        color: $text-color;
        background-color: $background-color;
        border-color: $focus-border-color;
        outline: 0;
        box-shadow: 0 0 0 .2rem $focus-box-shadow-color;
      }
  }

  .new-password-input-group {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    background: #ffffff; 
  
    input.new-password-input {
        height: 2.5rem;
      flex-grow: 1;
      padding: 10px;
      border: 1px solid $box-border-color;
      border-radius: 4px 0 0 4px; // Rounded corners on the left side only
      &:focus {
        color: $text-color;
        background-color: $background-color;
        border-color: $focus-border-color;
        outline: 0;
        box-shadow: 0 0 0 .2rem $focus-box-shadow-color;
      }
    }
  
    button.btn-show-hide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    border-left: none;
      background: #ffffff; 
      border-color: $box-border-color;
      color: $text-color; // Text color for the button
      padding: 10px 20px; // Padding inside the button
      border-radius: 0 4px 4px 0; 
      width: 10rem;
      cursor: pointer;
      &:hover {
        background: $primary-color; 
        color: white;  
      }
    }
  }

  .new-password-input-group {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    background: #ffffff; 
  
    input.new-password-input {
        height: 2.5rem;
      flex-grow: 1;
      padding: 10px;
      border: 1px solid $box-border-color;
      border-radius: 4px 0 0 4px; // Rounded corners on the left side only
      &:focus {
        color: $text-color;
        background-color: $background-color;
        border-color: $focus-border-color;
        outline: 0;
        box-shadow: 0 0 0 .2rem $focus-box-shadow-color;
      }
    }
  }
  
  .password-strength {
    text-align: left;
    margin-bottom: 1em;
    color: $text-color;
    &.Weak {
      color: $primary-color; 
    }
    &.Moderate {
      color: $weak-color
    }
    &.Strong {
      color: $strong-color
    }
  }
  
  .send-otp-button {
    border-radius: 4px; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 100%;
    padding: 10px;
    margin-bottom: 1em; 
    background-color: $primary-color;
    color: white;
    font-size: larger;
    border: none; 
    outline: none;
    &:hover {
        background-color: darken($primary-color, 10%); 
      }
      &:active {
        background-color: darken($primary-color, 20%); 
      }
  }
  
  .reset-password-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 2.5rem;
    margin-top: 2rem;
    width: 100%;
    padding: 10px;
    margin-bottom: 1em; 
    background-color: $primary-color;
    color: white;
    font-size: larger;
    border: none; 
    outline: none;
    &:hover {
        background-color: darken($primary-color, 10%); 
      }
      &:active {
        background-color: darken($primary-color, 20%); 
      }
  }
  
  .options-container {
    font-size: 0.9em;
    margin-top: 1rem;
  }
  
  .option-link {
    color: $hypertext-color;
    text-decoration: none;
    margin: 0 0.5em;
  
    &:hover {
      text-decoration: underline;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  
  .request-otp-button,
  .reset-password-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    flex: 1;
    margin: 0 5px; // Adds a little space between the buttons
    padding: 10px;
    font-size: larger;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    outline: none;
    &:disabled {
        background-color: #e9ecef;
        color: #6c757d; 
        cursor: not-allowed; 
    
        &:hover {
          background-color: #e9ecef; 
          color: #6c757d; 
        }
      }
  }
  
  .request-otp-button {
    background-color: $primary-color; 
    color: white;
    &:hover {
      background-color: darken($primary-color, 10%); 
    }
  }
  
  .reset-password-button {
    background-color: $primary-color;
    color: white;
    &:disabled {
        background-color: #e9ecef; 
        color: #6c757d; 
        cursor: not-allowed;
        &:hover {
        background-color: darken($primary-color, 10%);
        }
    }
  }

  .timer {
    color: $text-color;
    font-size: 0.9em;
  }

}

@media (max-width: 768px) { 
  .forgot-password-container {
    .form-container {
      margin-top: 5%;
      max-width: 90%;
    }

    .email-input,
    .otp-input,
    .new-password-input,
    .password-input,
    .submit-btn,
    .request-otp-button,
    .reset-password-button {
      width: 100%; 
    }

    .new-password-input-group {
      input.new-password-input,
      button.btn-show-hide {
        width: auto; // Adjust width as needed
        flex: 1; // This makes sure the input and the button share the available space
      }
    }
    .password-strength,
    .options-container {
      flex-direction: column;
    }
    

    .button-container {
      flex-direction: column; 

      .request-otp-button,
      .reset-password-button {
        margin: 10px 0;
      }
    }

    button.btn-show-hide {
      padding: 10px 15px; // Adjust the padding to better fit the smaller screen
    }
  }
}