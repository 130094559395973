.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    padding-right: 1rem;
    position: relative;
  }



  .logo {
    background-color: #D82121; 
    color: #FFF; 
    padding: 0.7rem;
    border-radius: 1px; 
    font-weight: bold;
    font-size: x-large;
    cursor: pointer;
  }
  
  .user-name {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    background-color: #ffffff;
    border: 1px solid #D82121;
    margin-left: auto;
    padding: 0.6rem 1.5rem !important;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
    cursor: pointer;
    position: relative;
  }

  .user-icon {
    margin-right: 0.5rem; 
    font-size: 20px;
    color: #D82121;
  }

  .dropdown-item-icon {
    margin-right: 0.5rem; 
    margin-left: 0.7rem;
    font-size: 14px;
    color: #D82121;
  }

  .navbar-container .btn-secondary {
    --bs-btn-color: #000000 ;
    --bs-btn-bg: #ffff ;
    --bs-btn-border-color: #D82121 ;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-color: #000;


    --bs-btn-active-border-color: #D82121;
    --bs-btn-active-bg: #ffff;
    --bs-btn-active-color: #000;
    --bs-btn-hover-border-color: #D82121 ;
  }

  .dropdown-menu {
    --bs-dropdown-link-active-bg: #fff !important;
    --bs-dropdown-link-hover-bg: #fff !important;
    --bs-dropdown-link-active-color: #000 !important;
  }
  .custom-dropdown-menu {
    width: calc(100% - 7%); 
    margin-top: 0.5rem;
    border-radius: 0.25rem;
    border-color: #D82121;
    box-sizing: border-box;
  }

  .user-name-wrapper {
    display: inline-block; 
    position: relative; 
  }