body .home-container {
    padding-left: 2%;
}
.home-container {
    color: #111;
    font-style: normal;
    .header-text {
        font-size: 40px;
        font-weight: 400;
    }
    .mt-4 {
        margin-top: 3rem!important;
    }
    .sub-header-text {
        margin-top: 1.5rem;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 2.5rem;
    }
    .commodity-widget {
        border: 1px solid rgba(17, 24, 39, 0.10);
        border-radius: 4px;
        margin-right: 1rem;
        background-color: #FFFFFF;
        .commodity-title {
            font-size: 56px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .commodity-color {
            border-radius: 33px;
            display: flex;
            text-align: center;
            align-items: center; 
            justify-content: center;
            padding: 8px 24px;
            width: 34%;
        }
        .btn-notification {
            color: #FFF;
            width: 100%;
            padding: 12px 16px;
            border-radius: 2px;
            background: #D82121;
            box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.10), 0px 0px 0px 1px rgba(17, 24, 39, 0.10);
            .icon-arrow {
                margin-left: 1rem;
                color: #FFF;
            }
        }
    }
    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #D82121;
        
    }
}

