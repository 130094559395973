.signup-background {
    background-image: url('../../assets/images/background/sign_up_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 100vh;
}

.text-container {
    color: #fff;
    padding: 6rem 6rem 0 6rem;
    .sub-text {
        font-size: 1.5rem;
    }
    .header-text {
        font-size: 4rem;
        background: #d22020;
    }
    .data-info {
        margin: 3rem 0 3rem 1.5rem;
        .dot {
            width: 0.6rem;
            background: red;
            height: 0.6rem;
            position: absolute;
            transform: rotate(50deg);
            margin-left: -2rem;
            margin-top: 2.5rem;
        }
        span {
        font-size: 2rem;
        font-weight: bold;
        }
        div {
            font-size: 1.5rem;
        }
    }
    p {
        padding-left: 3rem;
        padding-right: 3rem;
        font-weight: bold;
        font-size: smaller;
        color: rgb(11, 32, 4);
    }
    ul {
        list-style: none;
        li {
            position: relative;
            padding-bottom: 1.3rem;
            color: white;
            font-weight: bold;
            svg {
                padding-right: 0.5rem;
                color: rgb(112,183,87);
            }
        }
    }
    .footer-text {
        font-size: larger;
    }
}


.login-link {
    color: black; 
    text-decoration: none; 
}

.account-text {
    color: #7A7A7A;
}


.signup-container {
    border-radius: 0.5rem;
    padding: 0.2rem;

    .social-icon {
        width: 2.5rem;
        margin: 0 0.5rem;
        margin-bottom: 10%;
    }

    .form-container {
        margin: 2rem 5rem 0 5rem;
        .notification-text {
            padding-left: 1rem;
            margin-top: 2rem;
            text-align: center;
        }
        .notification-btn {
            width: 80%;
            margin-left: 3rem;
            background: black;
        }
        .md-icon {
            width: 4rem;
            height: 4rem;
            margin: 1rem 0 1rem 43%;
        }
        label {
            font-size: large;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
        .input-container {
            margin: 0 2rem;
            .submit-btn {
                width: 100%;
                height: 3rem;
                background-color: #d52322;
                color: white;
                font-size: larger;
                margin-top: 2%;
            }
            .coupon-code-label {
                text-align: right;
                // color: white;
                cursor: pointer;
            }
        }
    }
    .notification-description{
        text-align: center;
        align-self: start;
        margin-bottom: 5%;
    }
    .notification-submit-btn{
        width: 80%;
        height: 3rem;
        background-color: #d52322;
        color: white;
        font-size: larger;
        margin-left: 10%;
    }
    .notification-form-container {
        display: flex;
        flex-direction: column;
        align-items: center; 
        gap: 20px; 
    }

    .notification-md-icon{
        margin-top: 10%;
    }
    .user-created-icon {
        margin-top: 5%;
    }
    
    .notification-md-icon, .user-created-icon {
        max-width: 100%;
        height: auto;
    }

    .btn-show-hide {
        background-color: white;
        border: 1px solid #ced4da;
        color: #495057;
        width: 25%;
        transition: background-color 0.3s, color 0.3s;
      }
      .btn-show-hide:hover {
        background-color: #d52322;  
        color: white;  
      }
      .conditions-text{
        color: #7A7A7A;
      }

      .checkbox {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #fff;
        border: 1px solid #d52322;
        padding: 1px;
        cursor: pointer;
    }
    
    .credit-card-info {
        display: flex;
        align-items: center; 
        justify-content: center;
        font-size: larger;
    
        .icon-credit-card {
            margin-right: 1rem;
            color: #d52322;
        }
    
        p {
            margin: 0;
        }
    }

    .checkbox:checked {
        background-color: #d52322;
        border-color: #d52322;
    }
}



@media screen and (max-width: '1250px') {
    .position-absolute {
        width: 80%;
    }
}

@media screen and (max-width: '500px') {
    .position-absolute {
        width: 100%;
        top: 57% !important;
    }
}

/* ... Your previous CSS ... */

@media screen and (max-width: 768px) {

    .text-container {
        padding: 2rem; 
    }
    .text-container .sub-text {
        font-size: 1rem;
    }
    .text-container .header-text {
        font-size: 2.5rem; 
    }
    .text-container p {
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1rem; 
    }
    .text-container .data-info span {
        font-size: 1.5rem;
    }
    .text-container .data-info div {
        font-size: 1.2rem;
    }
    .signup-container .form-container {
        margin: 1rem 2rem; 
    }
    .signup-container .form-container .md-icon {
        width: 3rem;
        height: 3rem;
        margin: 0.5rem 0 0.5rem 30%;  
    }
    .signup-container .form-container .notification-btn, 
    .signup-container .notification-submit-btn {
        width: 90%; 
        margin-left: 5%;  
    }
    .signup-container .btn-show-hide {
        width: 40%; 
    }

}
