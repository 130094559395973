.parent-container {
    display: flex;
    align-items: center; /* Align vertically */
    justify-content: center; /* Align horizontally */
    height: 100vh; /* Full viewport height */
    width: 100%; /* Full width */
}

.login-container {
    border-radius: 0.5rem;
    padding: 0.2rem;
    width: 30rem;
    margin: auto;
    background: #ffffff;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);

    .form-group .text-danger {
        margin-top: 5px;
        font-size: 0.8rem; 
    }

    .md-icon {
        width: 4rem;
        height: 4rem;
        margin: 1rem 0 1rem 43%;
    }
    .font-size {
        font-weight: 250;
        a {
            text-decoration: none;
            color: black;
            font-weight: 500;
        }
    }
    .forgot-password {
        margin-left: 14rem;
        text-decoration: none;
        color: black;
        font-size: small;
        font-weight: 250;
    }
    .form-control {
        height: 40px;
    }
    label {
        font-size: large;
        margin-bottom: 0.5rem;
    }
    .input-container {
        margin: 2rem;
        .submit-btn {
            margin-top: 10%;
            width: 100%;
            height: 3rem;
            background-color: #d52322;
            color: white;
            font-size: larger;
        }
        .coupon-code-label {
            text-align: right;
            cursor: pointer;
        }
    }

    .btn-show-hide {
        background-color: white;
        border: 1px solid #ced4da;
        color: #495057;
        width: 25%;
        transition: background-color 0.3s, color 0.3s;
        height: 40px;
        }
        .btn-show-hide:hover {
        background-color: #d52322;  
        color: white;  
        }


        .account-text {
            color: #7A7A7A;
            margin-bottom: 10%;
        }

        @media screen and (max-width: 768px) {
            width: 90%; 
            margin-top: 2rem;
            padding: 1rem;
    
            .md-icon {
                width: 2.5rem; 
                height: 2.5rem;
                margin: 1rem auto; 
            }
    
            .font-size {
                font-weight: 200; 
                a {
                    font-size: 0.9rem; 
                }
            }
    
            .forgot-password {
                margin: 1rem auto;
                font-size: 0.8rem; 
                width: 100%; 
                text-align: center; 
            }
    
            .form-control {
                height: 35px; 
            }
    
            label {
                font-size: 1rem; 
            }
    
            .input-container {
                margin: 1rem; 
                .submit-btn {
                    margin-top: 5%; 
                    height: 2.5rem; 
                    font-size: 1rem; 
                }
            }
    
            .btn-show-hide {
                width: 30%; 
                height: 35px; 
            }
    
            .account-text {
                font-size: 0.9rem; 
            }
        }
}
