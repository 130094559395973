// Define colors, dimensions, and other reused values as variables
$primary-color: #D82121;
$primary-hover-color: #D82121;
$border-color: #ced4da;
$focus-border-color: #D82121;
$focus-box-shadow-color: #ffffff;
$background-color: #ffffff;
$text-color: #495057;
$disabled-color: #e9ecef;
$icon-margin-right: 0.5rem;
$sidebar-width: 25vh;
$navbar-height: 60px; 
$box-border-color: #ced4da;

.profile-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-left: 2%;
  padding-right: 1.2vh;
}

.main-container {
  display: flex;
  flex-direction: row;
  align-items: center; // This will vertically center the children
  justify-content: center; 
  height: calc(100% - var(--navbar-height))
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: $background-color;
  padding: 1rem;
  width: $sidebar-width;
}

.danger-item {
    color: $primary-color; // Or any shade of red you prefer
  }

.menu-item {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    margin-right: $icon-margin-right; 
    color: $primary-color;
  }

  &:hover {
    background-color: $background-color; // Use variable for background color
  }
}

.menu-divider {
    height: 1px;
    width: 100%; // Adjust the width as per your design
    background-color: $primary-color; // Divider color
    margin: 0.5rem 0; // Spacing around the divider
    align-self: center; // Center the divider within the sidebar
  }

.menu-spacer {
    height: 38rem; // Adjust here to increase the space between profile and change password menu items.
  }

.profile-container {
  margin-top: -8.5%;
  border-radius: 0.5rem;
  .form-group {
    margin-bottom: 1rem;
  }

  .submit-btn {
    margin-top: 2%;
    width: 50%;
    height: 3rem;
    background-color: #d52322;
    color: white;
    font-size: larger;
}

  label {
    display: block;
    margin-bottom: .5rem;
  }

  input {
    display: block;
    width: 50%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $text-color;
    background-color: $background-color;
    border: 1px solid $border-color;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:focus {
      color: $text-color;
      background-color: $background-color;
      border-color: $focus-border-color;
      outline: 0;
      box-shadow: 0 0 0 .2rem $focus-box-shadow-color;
    }
  }
  

  .btn-primary {
    color: $background-color;
    background-color: $primary-color;
    border-color: $primary-color;
    cursor: pointer;

    &:hover {
      background-color: $primary-hover-color;
      border-color: $primary-hover-color;
    }
  }
}

.change-password-container {
  margin-top: -8.5%;
  border-radius: 0.5rem;
  .form-group {
    margin-bottom: 1rem;
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    width: 40%;
    height: 2.5rem;
    background-color: #d52322;
    color: white;
    font-size: larger;
}

  label {
    display: block;
    margin-bottom: .5rem;
  }

  input {
    display: block;
    width: 50%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $text-color;
    background-color: $background-color;
    border: 1px solid $border-color;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:focus {
      color: $text-color;
      background-color: $background-color;
      border-color: $focus-border-color;
      outline: 0;
      box-shadow: 0 0 0 .2rem $focus-box-shadow-color;
    }
  }
  

  .btn-primary {
    color: $background-color;
    background-color: $primary-color;
    border-color: $primary-color;
    cursor: pointer;

    &:hover {
      background-color: $primary-hover-color;
      border-color: $primary-hover-color;
    }
  }

  .change-password-title {
    font-weight: bold;
    margin-right: 20px;
  }

  .change-password-description {
    font-weight: normal;
    margin-right: 20px; 
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .password-criteria-list {
    list-style: none; /* Removes default list style */
    padding: 0;
  }
  
  .password-criteria-list li {
    position: relative;
    padding-left: 25px; /* Provides space for the info symbol */
  }
  
  .password-criteria-list li::before {
    content: 'ℹ️';
    position: absolute;
    left: 0; /* Aligns the info symbol to the left */
  }
}

.input-container.no-hover {
    &:hover, &:focus {
      border-color: $disabled-color;
      box-shadow: $text-color; // Removes any box-shadow on hover/focus
      background-color: $disabled-color;
    }
  }

.form-control {
    height: 40px;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #D82121;
    
}


.password-input-group {

  width: 40%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff; 

  input.password-input {
      height: 2.5rem;
    flex-grow: 1;
    padding: 10px;
    border: 1px solid $box-border-color;
    border-radius: 4px 0 0 4px; // Rounded corners on the left side only
    &:focus {
      color: $text-color;
      background-color: $background-color;
      border-color: $focus-border-color;
      outline: 0;
      box-shadow: 0 0 0 .2rem $focus-box-shadow-color;
    }
  }

  button.btn-show-hide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border-left: none;
  background: #ffffff; 
  border-color: $box-border-color;
  color: $text-color;
  padding: 10px 20px; 
  border-radius: 0 4px 4px 0; 
  width: 30%;
  cursor: pointer;
  &:hover {
      background: $primary-color; 
      color: white;  
    }
  }
}



.profile-title {
  font-weight: bold;
  margin-right: 20px;
}

.profile-description {
  font-weight: normal;
  margin-right: 20px; 
  margin-top: 2rem;
  margin-bottom: 2rem;
}